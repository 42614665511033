.app{
  max-width: 1200px;
  width: 100%;
  margin: 100px auto;
  box-shadow: 0 0 5px #ccc;
}
.details{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 0;
}
.details .big-img{
  max-width: 500px;
  min-width: 290px;
  overflow: hidden;
  margin: 25px;
}
.big-img img{
  width: 100%;
  height: 100%;
  max-height: 400px;
  display: block;
  object-fit: cover;
  aspect-ratio: 1;
}

.details .box{
  width: 50%;
  /* max-width: 500px;
  min-width: 290px; */
  margin: 25px;
}
.box .row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.box .row h2{
  text-transform: uppercase;
  letter-spacing: 2px;
}
.box .row span{
  color: crimson;
}
.box .colors button{
  width: 30px;
  height: 30px;
  border: 1px solid #333;
  outline: none;
  margin-right: 5px;
  cursor: pointer;
}
.box p{
  line-height: 1.5;
  margin: 15px 0;
}
.thumb{
  width: 100%;
  /* height: 100px; */
  display: flex;
  cursor: pointer;
  margin: 10px 0;
  flex-wrap: wrap;
}
.thumb img{
  width: 90px;
  height: 100%;
  display: block;
  border: 1px solid #ddd;
  margin-right: 5px;
  opacity: 0.7;
  border-radius: 5px;
  margin-bottom: 5px;
object-fit: cover;
aspect-ratio: 1;
}
.Search{
  display: flex;
}
.thumb img.active{
  opacity: 1;
  border: 1px solid lightseagreen;
}
.box .cart{
  background: #333;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 25px;
  margin-top: 15px;
}




@media (max-width: 992px ) {
  .mediaQuery .swiper{
    width: 45%;
  }
  .details .box {
    width: 36%;
}
}
@media (min-width: 1200px) {
  .details .swiper .swiper-button-next{
    right:60px !important;
  }
}



@media (min-width: 992px) {
  .details .swiper {
    width: 50%;
  }
  .details .box {
    width: 40%;
  }
  .details .swiper .swiper-button-next{
    right:11px;
  }
}


 @media (max-width: 767px) {
  .mediaQuery .swiper{
    width: 50% ;
  }
 
} 


@media (max-width: 585px) {
  .mediaQuery .swiper{
    width: 100% ;
  }
  .details .box {
    width: 100%;
}
}


@media (max-width: 500px){
  .thumb{
    height: 50px;
  }
  .thumb img{
    width: 50px;
  }
}