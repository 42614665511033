@font-face {
    font-family: 'Times New Roman', Times, serif;
    src: url("../font/brandon-grotesque-light-58a8a4b38001d.otf");
}

@font-face {
    font-family: brandon-regular;
    src: url("../font/brandon-grotesque-thin-italic-58a8a3a8861fe.otf");
}


@font-face {
    font-family: 'brandon_printedtwo';
    src: url("../font/brandonPrinted/BrandonPrinted-Two.otf");
}

.row {
    margin-right: 0px !important;
}

body {
    height: 100%;
    font-family: 'Times New Roman', Times, serif;
    color: #74645a;
    background-color: white;
    font-size: 17px;
    line-height: 25px;
    font-weight: 300;
    margin: 0;
}

div {
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
}
#shoponline{
    display: none;
}


.backImage img{
    width: 100%;
    /* height: 400px; */
    object-fit: cover;
    aspect-ratio: 1;
}


.back-link {
      text-decoration: none;
      color: #000000;
      align-items: center;
      margin: 10px 20px;
  }
  
  .back-icon {
    font-size: 20px;
    margin-right: 5px;
  }
  
  .back-button {
    font-size: 16px;
    font-weight: bold;
  }
/***************************************************Header*******************************************/
#HeaderSection {


    position: relative;
    width: 100%;
    height: 100%;
    background: url(../Images/Z62_7749.JPG) no-repeat center;
    background-size: cover;
    text-align: center;
    padding: 0 0 100px 0;
    min-height: 860px;
}



#HeaderSection header::after {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
}

.header-wrap {

    /* max-width: 1170px; */
    overflow: hidden;
    margin: 0 auto;
}

header .menu {
    background-color: transparent;
    position: relative;
    z-index: 1;
}

.logo1 {
    display: none;
}

.logo-img {
    width: 100%;
}


header .menu ul {
    white-space: nowrap;
    padding: 0;
    font-size: 0;
    text-align: center;
    margin: 0 auto;
}

header .menu li {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    letter-spacing: 2px;
    display: inline-block;
    line-height: 0px;
    width: 18%;
    text-transform: uppercase;
    font-size: 18px;
}

header .menu li a {
    text-decoration: none;
    color: #fff;
}

/* header .menu li a:hover {
    text-decoration: underline;
    padding: 5px;
    color: #fff;
} */
.textHeader {
    max-width: 700px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    line-height: 60px;
    letter-spacing: 3px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 45px;
    color: #ffffff;
    z-index: 1;
}

.scroll-down {
    position: absolute;
    bottom: 160px;
    left: 50%;
    margin-left: -22px;
    display: block;
    z-index: 1;
}

.scroll-down img {
    width: 100%;
    vertical-align: middle;
}

.headerLogo {
    display: none;
}

/*****************************************Header fixed******************************************************/
header.fixed ul{
    position: fixed;
    background-color: white;
    margin-top: 0 !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999999 !important;
}

header.fixed h1 {
    display: none;
}

header.fixed .header-wrap {
    max-width: 1400px;
    margin: 0 auto;
}
header.fixed .logo1 {
    display: block;
    width: 100px;
    margin: 12px auto;
}

header.fixed .menu {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    -webkit-transition: background-color 200ms ease-out 200ms;
    -moz-transition: background-color 200ms ease-out 200ms;
    -o-transition: background-color 200ms ease-out 200ms;
    transition: background-color 200ms ease-out 200ms;
}

header.fixed .logo-img {
    display: none;
}

header.fixed .logo1 {
    display: block;
}

header.fixed .menu a {
    color: black;
    text-decoration: none;
}

header.fixed ul {
    line-height: 100px;
    padding: 0;
}

header.fixed .menu li {
    vertical-align: middle;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    letter-spacing: 2px;
    display: inline-block;
    line-height: 0px;
    width: 20%;
    text-transform: uppercase;
    font-size: 18px;
}

/***************************** Header fixed1 ********************************************************/
/***************************** Header fixed1 ********************************************************/
.header1 {
    top: 243px;
    background-color: rgba(255, 246, 235, 0.92);
    position: relative;
    display: none;
}
.header1 .menu {
    background-color: transparent;
    position: relative;
    z-index: 1;
}
.header1 .menu ul {
    white-space: nowrap;
    padding: 25px 0 20px 0;
    font-size: 0;
    text-align: center;
    margin: 0 auto;
    background-color: white;
}
.header1 .menu li {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    letter-spacing: 2px;
    display: inline-block;
    line-height: 0px;
    text-transform: uppercase;
    font-size: 2.5vw;
    width: 25%;
    font-weight: 700;

}
.header1 .menu li a {
    text-decoration: none;
    color: #74645a;
}
.fixed1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(255, 246, 235, 0.92);
}
/*********************************** OurSTory Section**********************************************************/
.our-story {
    background-color: white;
    text-align: center;
    padding: 80px 0 0;
      
}

.our-story .content {
    width: 100%;
}

.content {
    max-width: 1170px;
    margin: 0 auto;
}

.our-story h2 {
    width: 100%;
    background-color: white;
    position: relative;
    top: -120px;
    padding: 80px 0 20px 0;
    font-size: 30px;
    color: black;
   font-family:  'Times New Roman', Times, serif;
   font-weight: 300;
}

.our-story h2:after {
    background-color: black;
}

h2:after {
    margin: 10px 0 0 0;
    width: 30px;
    height: 2px;
    margin: 0 auto;
    content: " ";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -15px;
}

.our-story blockquote {
    padding: 0 80px 0 80px;
    letter-spacing: 2px;
    margin: 0 auto;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    line-height: 30px;
    font-size: 20px;
    position: relative;
    top: -72px;
    text-align: justify !important;
}

.our-story .imgClass {
    max-width: 110px;
    position: relative;
    top: -40px;
}
/************************************************genesis***************************************************/

.genesis {
    min-height: 510px;
    background: #ecb656 url(../Images/section.jpg) no-repeat center;
    background-size: 2560px 605px;
}

.genesis .description {
    margin: 0 auto;
    padding: 55px 19px 70px;
    text-align: center;
    width: 395px;
}

.genesis .description h3 {
    color: #fff;
    margin: 0 0 57px 0;
    font-family: 'Times New Roman', Times, serif;
}
.genesis .description p {
    color: #fff;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

.mobile-bg {
    display: none;
}

/**************************************************connect**************************************************/
#connect {
    text-align: center;
    padding: 70px 0 40px 0;
}

.rel {
    position: relative;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 20px 0;

}

#connect h2::after {
    background-color: #5dbdc5;
    margin: 10px 0 0 0;
    width: 30px;
    height: 2px;
    margin: 0 auto;
    content: " ";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -15px;
}

.labelConnect::before {
    content: "Newsletter:";
}

#connect .newsletter .labelConnect {
    position: relative;
    line-height: 70px;
    letter-spacing: 1px;
    float: left;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
}

#connect fieldset {
    display: inline-block;
    width: 70%;
    padding-top: 10px;
}

#connect .newsletter input[type=email] {
    background-color: transparent;
    padding: 0 3%;
    width: 60%;
    height: 50px;
    border: 2px solid #a3968b;
    border-right: none;
    float: left;
    font-family: 'Times New Roman', Times, serif;
    font-size: 15px;
    outline: none;
}

#connect .newsletter input[type=submit] {
    float: left;
    height: 50px;
    color: white;
    background-color: #74645a;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 15px;
    text-transform: uppercase;
    border: 0;
    letter-spacing: 1px;
    width: 33%;
    -khtml-border-radius: 0;
    cursor: pointer;
}

.title-followus::before {
    content: "Follow us:";
}

.title-followus {
    position: relative;
    text-align: left;
    letter-spacing: 1px;
    line-height: 70px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
}

.socialmedia {
    text-align: center;
}

.socialmedia ul {
    padding-left: 0rem !important;
}

.socialmedia ul li {
    display: inline-block;
    border-radius: 50%;
}

.socialmediaF {
    text-align: center;
}

.socialmediaF ul {
    padding-left: 0rem !important;
}

.socialmediaF ul li {
    display: inline-block;
    border-radius: 50%;
}

.fb {
    width: 67px;
    height: 67px;
    background: url(../Images/fbIcon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transition: filter 0.5s;
    cursor: pointer;
}

.fb:hover {
    filter: grayscale(100%);
}


.tw {
    width: 96px;
    height: 95px;
    background: url(../Images/twIcon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.tw:hover {
    background: url(../Images/twIcon-hover.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.in {
    width: 96px;
    height: 95px;
    background: url(../Images/insIcon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transition: filter 0.5s;
    cursor: pointer;
}

.yt {
    width: 86px;
    height: 85px;
    background: url(../Images/ytIcon.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.in:hover {
    filter: grayscale(100%);
}

.yt:hover {
 
    background: url(../Images/ytIcon-hover.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

/* ****************************************************************************************** */
/* ****************************************************************************************** */
/* ************************************our range & Slider*************************************** */
#ourrange {
    background-color: white;
    padding: 80px 0 0 0;
}

#ourrange h2 {
    color: black;
    margin-bottom: 80px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 30px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    position: relative;
    text-align: center;
}

#ourrange h2::after {
    background-color: black;
    margin: 10px 0 0 0;
    width: 30px;
    height: 2px;
    margin: 0 auto;
    content: " ";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -15px;
}

.swiper {
    width: 100%;
    height: auto;
}

.swiper-slide {
    margin-right: 0px;
}
/* Centered slider */
.swiper-container {
    margin: 0 auto;
    width: 80%;
}
/* Navigation arrows */
.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: auto;
    height: auto;
    margin: -10px 0 0 0;
    padding: 10px;
    z-index: 10;
    cursor: pointer;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    transition: opacity 0.2s ease;
    width: calc(var(--swiper-navigation-size)/ 26 * 27) !important;

}
.swiper-button-prev:hover,
.swiper-button-next:hover {
    opacity: 0.8;
}
.swiper-button-prev {
    left: 10px;
}
.swiper-button-next {
    right: 10px;
}
/* Pagination dots */
.swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets
.swiper-pagination-horizontal,
 .swiper-pagination-custom,
 .swiper-pagination-fraction {
 bottom: var(--swiper-pagination-bottom,0px) !important;
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}
.swiper-pagination-bullet-active {
    background-color: rgba(0, 0, 0, 0.5);
}
.brandNameSlider {
    color: #ec2227;
    margin-bottom: 20px;
    min-height: 1.4em;
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
}
.prev-next {
    padding-top: 38px;
    text-align: center;
}
.prev-next button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
.prev-next button {
    width: 90px;
    color: #74645a;
    border: 2px solid #74645a;
    margin: 0 0 0 -2px;
    height: 40px;
    width: 90px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
    position: relative;
}
.prevIcon {
    position: absolute;
    top: 13px;
    left: 0;
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 15px !important;
    text-transform: none !important;
    color: aliceblue !important;
}
.nextIcon {
    position: absolute;
    right: 0;
    top: 13px;
}
.ingredients {
    color: #bb5456;
    margin: 20px auto;
    width: 99%;
    display: inline-block;
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 1px;
    text-align: center;
}

.slider-container {
    padding-top: 35px;
}

.slider-container img {
    width: 100%;
}

.slider-container2 {
    padding-top: 35px;
    position: relative;
}


.media-links:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(29, 28, 33, 0.5);
    filter: alpha(opacity=0);
    opacity: 0;
    transition: all 0.2s linear;
}
.overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 58%);
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    
}
.overlay-text span {
    display: block;
    background-color: rgb(0 0 0 / 58%);
    color: #fff;
    cursor: pointer;
    padding: 10px 20px; 
    border-radius: 10px;
    transition: background-color 0.3s ease; 
  }
  
  .overlay-text:hover  {
    background-color: rgb(0 0 0 / 75%); 
  }
  .overlay-text:hover span {
    background-color: rgb(0 0 0 / 75%); 
  }

.slider-container2 img {
    width: 100%;
    /* height: 400px; */
    object-fit: cover; 
     aspect-ratio: 1; 
}
.modalImg{
    width: 100%;
    object-fit: cover; 
     aspect-ratio: 1; 
}


@media screen and (max-width: 992px) {
    /* .slider-container2 img {
        height: 250px;
    } */

    /* .btn-brand-name{
        padding: 5px 20px 5px 20px !important;
    } */
}

@media screen and (max-width: 754px) {
    /* .slider-container2 img {
        height: 150px;
    } */
}

@media screen and (max-width: 500px) {
    /* .slider-container2 img {
        height: 400px;
    } */

    /* .btn-brand-name{
        padding: 2px 10px 2px 10px !important;
    } */
}

/* ****************************************************************************************** */
/* ****************************************************************************************** */
/* ****************************************************************************************** */
/* ****************************************************************************************** */
/* *************************************Stockists***************************************************** */


#stockists {
    text-align: center;
    padding: 80px 0 40px;
    background-color: white;
}
#stockists h2 {
    margin-bottom: 60px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 30px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    position: relative;
    color: black;
}
#stockists h2::after {
    background-color: black;
    margin: 10px 0 0 0;
    width: 30px;
    height: 2px;
    margin: 0 auto;
    content: " ";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -15px;
}
#stockists p{
    padding: 0 80px 0 80px;
    letter-spacing: 2px;
    margin: 0 auto;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    line-height: 30px;
    font-size: 20px;
    position: relative;
}



.intro-country {
    text-align: left;
    margin-top: 58px;
    margin-bottom: 1rem;
}

.tabs {
    display: block;
}

.accordion-container {
    display: none;
}

.map {
    width: 100%;
    margin: 60px 0;
    background-color: #ec2227;
    width: 50%;
    float: left;
    position: relative;
    min-height: 398px;
}

.map h4 {
    color: #fff;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    text-transform: uppercase;
    font-size: 30px;
}

.tabs ul {
    text-align: center;
    margin: 50px 0 0 0;
}

.tabs ul li {
    padding: 10px 20px;
    /* background-color: whitesmoke; */
    /* border-radius: 8px; */
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
    margin-right: 30px;
    color: #74645a;
    cursor: pointer;
    border-bottom: 2px solid #000;
}

.active {
    color: black !important;
    position: relative !important;
    /* display: flex; */
    align-items: center;
}

.AdrsIcon {
    position: absolute;
    left: 29px;
    top: -22%;
    transform: translateY(22%);
}
.tabs ul li:hover {
    color: black;

}
.info-country {
    position: relative;
    top: 60px;
    text-align: left;
    min-height: 398px;
    width: 100%;
    /* float: right; */
    /* background-color: lightgray; */
    text-transform: uppercase;
}
.englob-country {
    padding: 0 !important;
}

.englob-country h3 {
    letter-spacing: 1px;
    font-size: 18px;
    color: #5dbdc5;
    font-weight: 400;
    margin-bottom: 20px;
}

.englob-country h4 {
    font-size: 15px;
    line-height: 20px;
    margin: 20px 0 20px 0;
    text-transform: none;
    font-weight: 900;
    font-family: 'Times New Roman', Times, serif;
}
.englob-country p {
    font-size: 15px;
    line-height: 20px;
    margin: 20px 0 20px 0;
    text-transform: none;
}

/***********Accordion*******************/


.ui-accordion-header {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.ui-accordion-header:hover {
    background-color: #e5e5e5;
}

.ui-accordion-header .ui-accordion-header-icon {
    margin-right: 10px;
}

.ui-accordion-header.ui-state-active {
    background-color: #ddd;
}

.ui-accordion-header.ui-state-active .ui-accordion-header-icon {
    transform: rotate(180deg);
}

ul.accordion-content li {
    list-style-type: none;
}

.accordion-container {
    margin: 10px;
    /* border: 1px solid #ddd; */
    border-radius: 5px;
}

.accordion-header {
    padding: 13px 20px;
    margin: 8px 20px;
    cursor: pointer;
    /* padding: 10px; */
    /* background-color: #eee; */
    border: 2px solid #746057;
    position: relative;
    transition: all 0.2s ease-in-out;
    color: #746057;
    letter-spacing: 1px;
    font-size: 16px;




}

.accordion-header.active {
    /* background-color: #ddd; */
    color: #ec2227;
    border: 2px solid #ec2227;

}
/* .accordion-header-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-top: 2px solid #666;
    border-right: 2px solid #666;
    transform: rotate(45deg);
    transition: all 0.1s ease-in-out;
  } */
.accordion-header.active .accordion-header-icon {
    transform: rotate(225deg);
}

.accordion-menu {
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}
.accordion-menu.open {
    padding: 10px;
    transition: max-height 0.2s ease-in-out;
}
/***************************************************contact us****************************************************** */

#contactus {
    text-align: center;
    padding: 80px 0 0 0;
}
#contactus h2 {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 30px;
    color: black;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    position: relative;
}
#contactus h2:after {
    margin: 10px 0 0 0;
    width: 30px;
    height: 2px;
    margin: 0 auto;
    content: " ";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -15px;
    background-color: black;
}
#contactus p {
    padding: 0 80px 0 80px;
    letter-spacing: 2px;
    margin: 0 auto;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    line-height: 30px;
    font-size: 20px;
    position: relative;
}
#contactus h4 {
    margin: 50px 0 0 0;
    text-align: left;
    text-transform: uppercase;
    font-size: 15px;
}
#contactus h4 span {
    font-family: 'Times New Roman', Times, serif;
    margin: 0 0 10px 10px;
}
#contactus input {
    width: 92%;
    margin: 0 40px 5px 0;
    outline: none;
    border: 2px solid black;
    background-color: transparent;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 18px;
    color: #74645a;
    height: 50px;
    padding: 15px 2%;
}
.formIn {
    text-align: left;
    margin-top: 10px;
}
.textArea {
    text-align: left;
    margin-top: 10px;
}
#contactus textarea {
    margin-top: 10px;
    resize: none;
    width: 98%;
    font-size: 15px;
    outline: none;
    padding: 10px 2%;
    border: 2px solid black;
    background: transparent;
    margin-bottom: 5px;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -khtml-border-radius: 0;
}
#contactus fieldset.button input[type=submit] {
    font-size: 18px;
    width: 98%;
    padding: 0.8em;
    border: 2px solid black;
    background-color: black;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
}
.recaptcha {
    width: 99%;
    margin-top: 20px;
    margin-bottom: 20px;
}

/**********************************************************footer********************************************/
footer {
    position: relative;
}

/* footer .socialmediaF::before {
    margin: 10px 0 0 0;
    width: 30px;
    height: 2px;
    margin: 0 auto;
    content: " ";
    display: block;
    position: absolute;
    top: -60px;
    left: 48%;
    margin-left: -15px;
    background-color: #ec2227;
} */
/* footer .bg-footer {
    background: transparent url(../Images/footer.jpg) no-repeat scroll center center/cover;
    padding: 10% 0 52%;
    position: absolute;
    width: 100%;
    z-index: -1;
} */
footer .socialmediaF {
    position: relative;
    text-align: center;
    float: left;
    width: 100%;
    padding-right: 27px;
    margin: 200px auto 0px;
}
footer .socialmediaF ul li {
    margin: 10px;
}
footer .copyright {
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    clear: both;
    font-family: 'Times New Roman', Times, serif;

}
footer .copyright a {
    color: red;
    text-decoration: none;
}
.socialmediaF .fb {
    width: 85px;
    height: 85px;
}
.socialmediaF .tw {
    width: 85px;
    height: 85px;
}
.socialmediaF .fb {
    width: 85px;
    height: 85px;
}
.socialmediaF .in {
    width: 85px;
    height: 85px;
}
/* ***********************Awards*************************************** */

#awards {
    text-align: center;
    padding: 80px 0 30px;
}

#awards h2 {
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    position: relative;
}

#awards h2:after {
    background-color: #fb7064;
    margin: 10px 0 0 0;
    width: 30px;
    height: 2px;
    margin: 0 auto;
    content: " ";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -15px;
}

.AwardsGrid {
    margin-top: 70px;
}

#awards h4 {
    color: #fb7064;
    padding: 0 0 40px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 36px;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 51px;
    text-align: left;
}

.awardsImg {
    padding-right: 40px;
}


.AwardsGTA li {
    float: left;
    text-transform: uppercase;
    width: 22%;
    margin-left: 10px;
    margin-bottom: 15px;
    min-height: 192px;
    list-style-type: none;
}
.AwardsGTA li img{
    width: 100%;
    max-width: 70px;

}
 .year {
    letter-spacing: 1px;
    margin: 0px 0 5px 0;
    font-size: 11px;
    line-height: 15px;
}
.color {
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    margin: 0px 0 3px 0;
    font-family: 'Times New Roman', Times, serif;
}
.name {
    letter-spacing: 1px;
    font-size: 11px;
    max-width: 100px;
    width: 100%;
    margin: 0 auto 2px;
    line-height: 15px;
}
.greatTaste img{
    width: auto;
    height: auto;
    float: left;
    margin: 50px 0 30px 0;
}

.greatTaste p {
    /* float: left; */
    margin: 0 0 25px 5%;
}

.dInMin{
    display: none;
}
.dInMax{
    display: block;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .socialmedia {
        text-align: left;
    }


    footer .socialmediaF::before {

        left: 49%;
    }

}

@media screen and (max-width: 1200px) {
    .content {
        max-width: 1000px;
        padding: 0 40px;
    }
    
}

@media screen and (max-width: 991px) {
    .rel {
        display: none;
    }


    .labelConnect::before {
        content: "Newsletter";
        padding-bottom: 10px;
    }

    #connect .newsletter .labelConnect::after {
        background-color: #5dbdc5;
        float: none;
        width: 30px;
        height: 2px;
        margin: 0 auto 0 -15px;
        content: " ";
        display: block;
        position: absolute;
        bottom: 16px;

        left: 50%;
    }

    .title-followus::before {
        content: "Follow us";
    }

    #connect {
        text-align: center;
        padding: 0px;
    }

    #connect .newsletter .labelConnect {
        content: "jihad";
    }

    .title-followus::after {
        background-color: #5dbdc5;
        float: none;
        margin: 10px 0 0 0;
        width: 30px;
        height: 2px;
        margin: 0 auto;
        content: " ";
        display: block;
        position: absolute;
        bottom: 13px;
        left: 50%;
        margin-left: -15px;
    }

    .content {
        max-width: 1000px;
        padding: 0 40px;
    }

    #connect .newsletter .labelConnect {
        float: none;
    }

    #connect fieldset {
        width: 80%;
        padding-top: 10px;
        margin-left: 20px;
    }

    .title-followus {
        text-align: center;

    }

    .socialmediaF {
        text-align: left;
    }

    .socialmediaF ul {
        text-align: center;
        padding-bottom: 52px;
    }
    #awards h4 {
       text-align: center;
       font-size: 25px;
    }
    .greatTaste img{
        float: none;
    }
    
    .greatTaste p {
        float: none;
    }
    .dInMin{
        display: block;
    }
    .dInMax{
        display: none;
    }
    #awards h4 {
        padding:0px;}
    
   
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .our-story {
        padding: 80px 0 60px;
    }

    .our-story blockquote {
        line-height: 41px;
        font-size: 30px;
        position: inherit;
        margin: 70px 0 0 0px;
    }

    .our-story h2 {
        top: 0;
        padding: 0;
    }

    .our-story blockquote {
        line-height: 41px;
        font-size: 30px;
        position: inherit;
        margin: 70px 0 0 0px;
    }


    .our-story .imgClass {
        position: static;
        margin-top: 25px;
    }

    .textHeader {
        font-size: 40px;
    }


}

@media screen and (max-width: 767px) {
    .our-story h2 {
        top: 0;
        padding: 0;
    }

    header.fixed .menu ul {
        display: none;

    }

    .header1 {
        display: none;
    }

    .mobileHeader {
        display: block !important;
    }

    .header-wrap .menu .clearfix .row.headerLogo .col-md-12 .logo-img {
        display: none;
    }

    header .menu li {
        font-family: 'Times New Roman', Times, serif;
        font-weight: 500;
        letter-spacing: 2px;
        display: inline-block;
        line-height: 0px;
        text-transform: uppercase;
        font-size: 18px;
        max-width: -10px !important;

    }

    .DNone {
        display: none;
    }

    .headerLogo {
        display: block;
    }

    .logo-img {
        vertical-align: middle;
        width: 144px;
    }

    #HeaderSection {

        min-height: 564px;
    }


    .textHeader {
        font-size: 20px;
        letter-spacing: 3px;
        line-height: 40px;
        max-width: 700px;
        text-transform: uppercase;
    }

    .our-story blockquote {
        padding: 25px 0 0 0;
        margin: 20px 0 0 0;
        line-height: 30px;
        font-size: 21px;
        top: 0;
    }

    .our-story {

        padding: 44px 0 20px;
    }

    .our-story .imgClass {
        top: 20px;
        margin-bottom: 39px;
    }

    .genesis {
        min-height: 0;
        background: none;
        /* margin: 0 10px; */
    }

    .genesis .description {
        width: auto;
        padding: 30px 22px;
        background-color: #ecb656;
    }

    .genesis .mobile-bg {
        display: block;
    }

    .genesis .mobile-bg img {

        display: block;
        width: 100%;
    }

    #stockists p {
        text-align: left;

    }

    .tabs {
        display: none;
    }

    .accordion-container {
        display: block;
    }
    #contactus {
        background-color: white;
    }

    #contactus input {
        width: 100%;
        padding: 15px 2%;
    }

    #contactus textarea {
        width: 100%;
    }

    #contactus fieldset.button input[type=submit] {
        width: 100%;
    }

    footer .socialmediaF:before {
        bottom: 40px;
        top: auto;
    }

    footer .socialmediaF {
        margin: 86px auto 0px;
    }
    
}

@media screen and (max-height: 430px) {
    .our-story .content {
        width: 100%;
    }

    .textHeader {
        font-size: 18px;
        line-height: 30px;
        top: 42%;
    }

}

@media screen and (max-width: 510px) {
    .AwardsGTA li {
        width: 29%;}
.swiper-button-next, .swiper-button-prev {
    margin-top: calc(-15px - (var(--swiper-navigation-size)/ 2 )) !important ;
}
  
}
@media screen and (max-width: 430px) {
    #connect .newsletter input[type=submit] {
        font-size: 15px;
        width: 40%;
    }

    footer .socialmediaF {
        padding-right: 10px;
    }

    .socialmediaF .fb {
        width: 65px;
        height: 65px;
    }

    .socialmediaF .tw {
        width: 65px;
        height: 65px;
    }

    .socialmediaF .fb {
        width: 65px;
        height: 65px;
    }

    .socialmediaF .in {
        width: 65px;
        height: 65px;
    }

    .socialmediaF .yt {
        width: 65px;
        height: 65px;
    }
    #ourrange {
        padding: 32px 0 35px 0;
    }
    #stockists {
        padding: 32px 0 40px;
    }
    .AwardsGTA li {
        width: 39%;}
        #connect fieldset {
            width: 100%;
          
        }
 .swiper-button-next, .swiper-button-prev {
 margin-top: calc(-26px - (var(--swiper-navigation-size)/ 2)) !important ;
}
    
}


@media screen and (max-width: 360px) {

    footer .socialmediaF ul li {
        margin: 0px;
    }
.swiper-button-next, .swiper-button-prev {
        margin-top: calc(-33px - (var(--swiper-navigation-size)/ 2)) !important ;
     }   
}

@media (min-width: 992px) and (max-width:1500px) {
    .col-lg-2 {
        flex: 0 0 auto !important;
        width: 16.666667% !important;
    }
}

.mobileHeader {
    display: none;
}

@media screen and (min-width: 767px) {
    header .menu ul {
        margin: -100px auto;
    }
}

@media screen and (min-width: 992px) {
    header .menu ul {
        margin: -145px auto;
    }

    header .menu li a {
        font-size: 20px;
    }
}




@media (min-width:1500px) {
    footer .socialmediaF::before {
        left: 49%;
    }
    
}
@media (min-width: 992px) {
    .col-lg-2 {
        flex: 0 0 auto;
        width: 15.666667%;
    }
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
    background-color: black;
}

.comImage{
    width: 100%;
    min-height: 398px;
    object-fit: cover;
}

.comAccImage{
    width: 100%;
    height: 198px;
    object-fit: contain;
}

.comLogo{
    width: 407px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mapAcc{
    width: 100%;
    background-color: transparent;
    width: 100%;
    float: left;
    position: relative;
    min-height: 198px;
}

.info-country-acc{
    position: relative;
    text-align: left;
    min-height: 198px;
    width: 100%;
    float: right;
    background-color: #1515158f;

    text-transform: uppercase;
}

.info-country-acc .comLogo{
    width: 120px !important;
}

/* .btn-brand-name {
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    border: 0;
    border-radius: 8px;
    padding: 10px 35px;
    color: black;
    outline: none;
    font-weight: bold;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 5px; */
    /* box-shadow: 3px 1px 35px -13px rgba(0,0,0,0.82);
-webkit-box-shadow: 3px 1px 35px -13px rgba(0,0,0,0.82);
-moz-box-shadow: 3px 1px 35px -13px rgba(0,0,0,0.82); */
/* } */

.loginContent{
    width: 100vw;
    height: 100vh;
    background-image: url("../Images/Z62_7749.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;
}

.loginContent .paper{
    width: 50vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
    border-radius: 25px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

@media screen and (max-width: 567px) {
    .loginContent .paper{
        width: 90vw;
    }
}

.BOX::after {
    background-color: transparent;
}

.backgroundContent{
    text-align: center;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 50%;
}

.backgroundContentImage {
    width: 100%;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    background-color: white;
    border-radius: 5px;
}

.storyContent {
    width: 100%;
    text-align: center;
}

.categoryBtn {
    display: flex;
    margin-top: 100px;
    margin-left: 10%;
    justify-content: space-between;
    width: 80%;
}

.categoryTable {
    width: 80%;
    margin-top: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.mobileHeader {
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 100;
    padding: 20px;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    max-height: 80px;
    padding: 20px;
  }
  
  .mobileHeader.open {
    max-height: 500px;
    padding: 20px;
  }
  
  .mobileHeader .mobileHeaderTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .mobileHeader .logo-img {
    width: 50px;
  }
  
  .mobileHeader .mobileHeaderItem {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;
  }
  
  .mobileHeader .mobileHeaderItem a {
    color: black;
    padding: 5px;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .mobileHeader.open .mobileHeaderItem a {
    opacity: 1;
    transform: translateY(0);
  }

  .swiper-wrapper {
    z-index: 0 !important;
}

.swiper, swiper-container {
    z-index: 0 !important;
}

.clearfix .logo {
    width: 28%;
}

.slider-container2 img:hover {
    cursor: pointer;
}